function UploadController(uploadToS3, $log, $mdDialog) {
  this.buttonText = 'Drop File or Click';

  Object.defineProperty(this, 'uploadJob', {
    get: () => uploadToS3.jobs[this.job._id] || {}
  });

  const upload = ( bucketName) => {
    if(this.uploadJob.completed) {
      // user may want to re-upload
      this.cancelUpload();
    }
    //TODO: finish implementing error handling in upload
    //this catch will never be triggerred at the moment
    uploadToS3.upload(this.job, bucketName).catch((rejection) => {
      $log.debug('upload rejected', rejection);
    });
  };

  this.cancelUpload = () => {
    uploadToS3.cancelUpload(this.job);
  };

  this.hasUpload = () => {
    return !!uploadToS3.jobs[this.job._id];
  };

  //TODO: move this somewhere else
  this.showS3dialog = (ev) => {
    $mdDialog.show({
      controller: DialogController,
      // templateUrl: 'jobs/upload/jobs.upload.cloud.dialog.tpl.html',
      // Due to known angular material but, must wrap in md-dialog
      template: `
      <md-dialog>
          <sq-cloud-upload-to-s3 on-cancelled='cancel()' on-selected='save(bucketName, name)'></sq-cloud-upload-to-s3>
      </md-dialog>
      `,
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose:true,
      fullscreen: true, //-xs and -sm breakpoints
    })
    // If files are provided, use them, if not, revert to the local files uploader
    .then((bucketName, name) => {
      if(bucketName) {
        upload(bucketName);
      } else {
        throw new Error("bucketName required");
      }
    })
    .catch((err) => {
      if(err) {
        $log.error(err);
      }
    });
  };

  function DialogController($scope, $mdDialog) {
    $scope.cancel = function() {
      $mdDialog.cancel();
    };

    $scope.save = function(bucketName) {
      $mdDialog.hide(bucketName);
    };
  }
}

angular.module('sq.jobs.upload.toS3.component',
['sq.jobs.upload.toS3.service', 'sq.jobs.upload.cloud.toS3.component'])
.component('sqUploadToS3', {
  bindings: {
    job: '<',
    label: '@',
  },
  controller: UploadController,
  templateUrl: 'jobs/upload/jobs.upload.toS3.tpl.html',
  transclude: true,
});
